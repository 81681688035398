<template>
  <div>
    <list-template
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        has-back
        @onChangePage="changeCurrentPage"
        @onHandle="handle"
    ></list-template>

    <!--  一对一辅导的弹出框  -->
    <el-dialog
        title="一对一辅导详情"
        :visible.sync="dialogVisible"
        width="800rem">
      <el-descriptions v-if="battleCont" title="" :column="1">
        <el-descriptions-item label="辅导科目">{{ battleCont.subject }}</el-descriptions-item>
        <el-descriptions-item label="辅导教师">{{ battleCont.teacher }}</el-descriptions-item>
        <el-descriptions-item label="辅导日期">{{ battleCont.end_time }}</el-descriptions-item>
        <el-descriptions-item label="辅导时长">{{ battleCont.duration }}</el-descriptions-item>
        <el-descriptions-item label="学习动力">{{ battleCont.study_power }}</el-descriptions-item>
        <el-descriptions-item label="知识点">{{ battleCont.abilityStr }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"coach","meta":{"title":"一对一辅导记录"}}},
  data(){
    return {
      dialogVisible:false,
      battleCont:{},
      // 表格配置
      tableConfig: [
        {
          prop:"subject",
          width: '120rem',
          label:"辅导科目"
        },
        {
          prop:"teacher",
          width: '120rem',
          label:"辅导教师"
        },
        {
          prop:"end_time",
          width: '140rem',
          label:"辅导日期"
        },
        {
          prop:"duration",
          width: '120rem',
          label:"辅导时长"
        },
        {
          prop:"abilityStr",
          label:"知识点"
        },
        {
          prop:"study_power",
          width: '220rem',
          label:"学习动力"
        },
        {
          handle:true,
          width: '120rem',
          label:"操作",
          render(){
            return["查看详情"]
          }
        }
      ],
      // 当前页码
      page: 1,
      total: 0,
      // 表格中的数据
      tableData:[],
      id:null
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods:{
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e;
      this.getData();
    },
    getData(){
      this.$_axios.get("/students/coach",{params: {id: this.id,page:this.page}}).then(res=>{
        let {data} = res.data;

        if (data && data.length) {
          data  .forEach(item => {
            item.abilityStr = item.ability.map(c_item => c_item.name)
            item.abilityStr = item.abilityStr.join('，')
          })
        }

        this.tableData = data;
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      })
    },
    handle(row){
      this.dialogVisible = true;
      this.battleCont = row
    }
  }
}
</script>

<style scoped>

</style>
